

const Constants = {

    pollId: 3141,
    assessmentObject:{},
    fontColor: "#ffffff",
    bgColor: "#09223f",
    btnColor: "#008b8b",
    presenterMode: 2,


    //Graph
     graphType: 1,
     graphData: [],
     graphColors: ["orange", "green", "red", "blue", "yellow", "gold"],

    //Websocket
    webSocketConfig:{connectionId: "",currentQuestion: 0},
    webSocketMessages:[],
    webSocketConnectionid: "",
    
    //WSconnectionId:"",

    // firebaseDB: {},
    // pollId: 0,
    // awsPublishURL: publishURL,
    // assessmentId: "",
    // userId: 1, // Default userId.... Later from storypulse
    // baseURL: function () {
    //     return baseURL;
    // },
    // imageURL: function () {
    //     return imageURL;
    // },
    // //Firebase
    // presenterMode: function () {
    //     var modeValue = 0;
    //     Firebase.database().ref("/liveAssessments/" + this.userId + "/Mode/").on('value', function (modeSnapshot) {
    //         modeValue = modeSnapshot.val();
    //     });
    //     return modeValue;
    // },
    // getFirebaseDB: function () {
    //     return Firebase.database();
    // },

    // updateMode: function (modeValue) {
    //     Firebase.database().ref("/liveAssessments/" + this.userId + "/").update({ "Mode": modeValue });
    // },

    // updateQuestion: function (currentQuesNo) {
    //     Firebase.database().ref("/liveAssessments/" + this.userId + "/" + this.pollId + "/").update({ "currentQuestion": currentQuesNo });
    // },

    // bgColor: "#09223f",
    // fontColor: "#ffffff",
    // btnColor: "#008b8b",


   


    // getPollList: function () {

    //     var pollList = [];

    //     return pollList;
    // }

};



export default Constants;