import React, { Component } from 'react';
import './App.css';
import logo from './resources/livepollIcon.svg';
import poweredby from './resources/poweredby.png';
import LABELS from './languages/en';
import SERVICES from './services/services.js';
import Constants from './constants.js';
import getWSService from "./services/webSocketService.js";



class App extends Component {

  constructor() {
    super();
    this.state = {
      titleBarDisplay: false,
      footerBarDisplay:false,
      startingPageDisplay: false,
      questionPageDisplay: false,
      endingPageDisplay: false,
      waitingPageDisplay: false,
      disconnectMessageDisplay:false,
      currentQuestionIndex: 0, // Current question index
      totalQuestions: 10, // Total number of questions
      currentQuestionIndexText: "",// to change
      pollId: 0,
      pollDetails: {},
      assessmentObject: {},
      userResponse: []
    };
    window.setQuestion = this.setQuestion.bind(this);
    window.setScreen = this.setScreen.bind(this,5);
  }



  componentDidMount() {
    this.setState({ titleBarDisplay: true });
    this.setState({ startingPageDisplay: true });
    this.setState({ footerBarDisplay: true });
  }


  alertMessage(){
    console.log("Called from outside");
 }



  showErroMessage() {
    document.getElementById("errorMessage").style.display = "block";

    var tempTimer = setInterval(function () {
      document.getElementById("errorMessage").style.display = "none";

      for (var i = 0; i < 4; i++) {
        document.getElementById("text" + (i + 1)).value = "";
      }
      document.getElementById("text1").focus();

      clearInterval(tempTimer);
    }, 2000);

  }





  async startPollBTNClick() {

    var pollid = document.getElementById("text1").value * 1000 +
      document.getElementById("text2").value * 100 +
      document.getElementById("text3").value * 10 +
      document.getElementById("text4").value * 1;

    console.log("======start poll btn click==========");
    console.log(pollid);

    if (pollid === 0 || 
      pollid === undefined || 
      pollid === null || 
      document.getElementById("text1").value =="" || 
      document.getElementById("text2").value =="" || 
      document.getElementById("text3").value =="" || 
      document.getElementById("text4").value =="") {
      this.showErroMessage();
    } else {
      Constants.pollId = parseInt(pollid);

      console.log(Constants.webSocketConfig);
      console.log(Constants.pollId);

      var pollDetailsObject = await SERVICES.getPollDetails(Constants.pollId, Constants.webSocketConfig.connectionId);

      console.log("=========pollDetailsObject==========");
      console.log(pollDetailsObject);

      var pollActive = JSON.parse(pollDetailsObject.body)["pollDetails"].isActive;

      console.log("=========pollActive=============");
      console.log(pollActive);
      if (pollActive == "false" || pollActive === undefined || pollActive === false || pollDetailsObject.statusCode === undefined || pollDetailsObject.statusCode === null) {
        this.showErroMessage();
      } else {
        this.setState({ pollDetails: JSON.parse(pollDetailsObject.body)["pollDetails"] }, function () {
          console.log("=====poll details==========");
          console.log(this.state.pollDetails);
          Constants.webSocketConfig.currentQuestion = parseInt(this.state.pollDetails.currentQuestion);
          this.setQuestion();
        });
      }

    }

  }




  reset() {
    this.setState({ titleBarDisplay: false });
    this.setState({ startingPageDisplay: false });
    this.setState({ footerBarDisplay: false });
    this.setState({ questionPageDisplay: false });
    this.setState({ endingPageDisplay: false });
    this.setState({ waitingPageDisplay: false });
  }




  setScreen(screen) {
    this.reset();

    switch (screen) {
      case 1:
        this.setState({ titleBarDisplay: true });
        this.setState({ startingPageDisplay: true });
        break;
      case 2:
        this.setState({ questionPageDisplay: true });
        break;
      case 3:
        this.setState({ endingPageDisplay: true });
        break;
      case 4:
        this.setState({ waitingPageDisplay: true });
        break;
      case 5:
        this.setState({disconnectMessageDisplay: true});

    }
  }





  setQuestion() {

    var currentQuestion = Constants.webSocketConfig.currentQuestion; // this must come from the presenter

    console.log(currentQuestion);

    this.setState({ currentQuestionIndex: currentQuestion });

    var questions = JSON.parse(this.state.pollDetails.assessmentObject)["questions"];
    var questionLength = questions.length;

    this.setState({ currentQuestionObject: questions[currentQuestion] }, function () {
      console.log("======current queston object=========");
      console.log(this.state.currentQuestionObject);

      if (this.state.currentQuestionObject.questionType == "NET_PROMOTER_SCORE") {
        for (var i = 0; i <= 10; i++) { //Need to set the scale
          this.state.currentQuestionObject.answerOptions.push({ answerText: i.toString() });
        }
      }

      console.log(this.state.currentQuestionObject);

      this.setState({ currentQuestionIndexText: (this.state.currentQuestionIndex + 1) + " of " + questionLength }, function () {
        this.setScreen(2);
      });
    });


  }





  submitBtnClick(){

    if(this.state.currentQuestionObject.questionType == 'TEXT'){
      this.setuserResponseObject();
    }
 
    console.log(this.state.userResponse);
    SERVICES.sendUserResponse(this.state.userResponse);

    var questions = JSON.parse(this.state.pollDetails.assessmentObject)["questions"];
    console.log(this.state.currentQuestionIndex);
    console.log(questions.length);
    (this.state.currentQuestionIndex < (questions.length -1)) ? this.setScreen(4) : this.setScreen(3); //Show ending screen if last question else wait screen

  }








  setuserResponseObject(answerIndex) {

    var questionType = this.state.currentQuestionObject.questionType;

    if (questionType != 'TEXT') {
      document.getElementById("optionBtn" + answerIndex).style.background = "orange"; //Change the color of the option button on click
    }




    var tempResponse = {
      "questionId": this.state.currentQuestionObject.id,
      "questionText": this.state.currentQuestionObject.questionText,
      "answerId": questionType == 'TEXT' ? null : this.state.currentQuestionObject.answerOptions[answerIndex].id,
      "answerText": questionType == 'TEXT' ? document.getElementById("commentText").value : this.state.currentQuestionObject.answerOptions[answerIndex].answerText
    }


    console.log("=======tempResponse=====");
    console.log(tempResponse);

    var userResponsetemp = this.state.userResponse;
    userResponsetemp.push(tempResponse);

    console.log("======userResponsetemp====");
    console.log(userResponsetemp);


    this.setState({ userResponse: userResponsetemp });




    //Move to next screen for single select question type. Wait for submit button click for others.
    if (questionType == "SELECT" || questionType == "NET_PROMOTER_SCORE") {
      this.submitBtnClick();
    }



  }






  changeFocus = index => {

      var timeInterval = setInterval(function () {
        if (index < 4) {
          
         // setTimeout(function () {
             // myField.focus();
              document.getElementById("text" + (index + 1)).focus();
         // }, 0);
        //  document.getElementById("text" + (index + 1)).click(); //To support in android browser as set focus alonge do not work.
        } else {
          document.getElementById("takePollBtn").focus();
        }
        clearInterval(timeInterval);
      }, 10);
  };






  render() {

    let titleBar,
      questionPage,
      endingPage,
      waitingPage,
      disconnectMessagePage,
      footerBar;

    if (this.state.titleBarDisplay) {

      titleBar = <div id="titlebar">
        <div className="logo">
          <img src={logo} alt="" className="logoImage" />
          <div id="logoText">{LABELS.logoText}</div>
        </div>
      </div>
    }

    


    if (this.state.startingPageDisplay) {

      titleBar = <div >
        <div className="titleMessage" >{LABELS.titleTextMessage}</div>

        <div id="inputTextBox" className="inputTextBox">
          <input id="text1" autoFocus={true} type="number" pattern="[0-9]" maxLength="1" className="pollId" tabIndex="1"  onKeyDown={event => { this.changeFocus(1, this) }} />
          <input id="text2" type="number" pattern="[0-9]" maxLength="1" className="pollId" tabIndex="2" onKeyDown={event => { this.changeFocus(2, this) }} />
          <input id="text3" type="number" pattern="[0-9]" maxLength="1" className="pollId" tabIndex="3" onKeyDown={event => { this.changeFocus(3, this) }} />
          <input id="text4" type="number" pattern="[0-9]" maxLength="1" className="pollId" tabIndex="4" onKeyDown={event => { this.changeFocus(4, this) }} /> 
        </div>

        <div id="errorMessage" className="errorMessage">{LABELS.errorMessage}</div>
        <button id="takePollBtn" className="button" onClick={this.startPollBTNClick.bind(this)} >{LABELS.pollBtnText}</button>
      </div>
    }



    if (this.state.questionPageDisplay) {


      questionPage = <div className="questionWindow">

        <div id="questionNoText" className="questionNoText">{this.state.currentQuestionIndexText}</div>

        {/*
<div id="questionImage" className="questionImage" data-ng-show="currentQuestionObject.questionType == 'IMAGE_TYPE' && currentQuestionObject.image !=''">
<img src="{{serviceURL}}storypulse/imageloader?assessmentImage={{currentQuestionObject.image}}" className="questionImage">
</div>*/}

        <div id="questionText" className="questionText">{this.state.currentQuestionObject.questionText}</div>


        <span id="optionTextSpan" className="optionTextSpan">

          {(this.state.currentQuestionObject.questionType != 'NET_PROMOTER_SCORE') &&
            <div className="optionsDIV" >

              {(this.state.currentQuestionObject.questionType == 'MULTI_SELECT' || this.state.currentQuestionObject.questionType == 'SELECT') &&

                this.state.currentQuestionObject["answerOptions"].map((options, index) => (
                  <ul id={"optionBtn" + index} className="optionText" key={index} onClick={this.setuserResponseObject.bind(this, index)}>
                    {this.state.currentQuestionObject["answerOptions"][index].answerText}
                  </ul>
                ))
              }

              {this.state.currentQuestionObject.questionType == 'TEXT' &&
                <div className="optionsCommentDIV">
                  <textarea id="commentText"></textarea>
                  <div id="commentBlankMessage" className="commentBlankMessage">{LABELS.commentBlankMessage}</div>
                </div>
              }



              {(this.state.currentQuestionObject.questionType == 'MULTI_SELECT' || this.state.currentQuestionObject.questionType == 'TEXT') &&
                <button className="submitBtn" onClick={this.submitBtnClick.bind(this)}>Submit</button>
              }

            </div>

          }


          {(this.state.currentQuestionObject.questionType == 'NET_PROMOTER_SCORE') &&
            <div className="optionsDIVNPS" >
              {this.state.currentQuestionObject["answerOptions"].map((options, index) => (
                <ul id={"optionBtn" + index} className="optionTextNPS" key={index} onClick={this.setuserResponseObject.bind(this, index)}>
                  {this.state.currentQuestionObject["answerOptions"][index].answerText}
                </ul>
              ))}
            </div>
          }





          {/* <div className="optionsDIV" data-ng-show="currentQuestionObject.questionType == 'SELECT'" >
<button className = "optionText" ontouchstart="" ng-repeat="option in currentQuestionObject.answerOptions"  ng-click="updateOptionSelect(option.id,option.answerText,null,currentQuestionObject.id,$event)" ng-disabled="buttonDisabled">
{{ option.answerText }}
</button>
</div>

<div className="optionsDIV" data-ng-show="currentQuestionObject.questionType == 'MULTI_SELECT'" >
<button id="{{ 'option' + $index }}" className = "optionText" ontouchstart="" ng-repeat="option in currentQuestionObject.answerOptions"  ng-click="updateOptionSelect(option.id,option.answerText,null,currentQuestionObject.id,$event)" ng-disabled="buttonDisabled">
{{ option.answerText }}
</button>
<button className="submitBtn" data-ng-click="submitBtnClick()">Submit</button>
</div>

<div className="optionsCommentDIV" data-ng-show="currentQuestionObject.questionType == 'COMMENTS'">
<textarea id="commentText" ng-model="textareaValue"></textarea>
<button className="submitBtnComment"  data-ng-click="updateOptionSelect(option.id,textareaValue,null,currentQuestionObject.id)">Submit</button>
<div id = "commentBlankMessage" className = "commentBlankMessage">{{commentBlankMessage}}</div>
</div>

<div className="imageOptionsDIV" data-ng-show="currentQuestionObject.questionType == 'IMAGE_TYPE'" >
<button id = "option.id" data-ng-show="option.image != undefined"  className = "imageOptions" ontouchstart="" ng-repeat="option in currentQuestionObject.answerOptions"  ng-click="updateOptionSelect(option.id,option.answerText,option.image,currentQuestionObject.id,$event)" ng-disabled="buttonDisabled">
<img src= "{{serviceURL}}storypulse/imageloader?assessmentImage={{option.image}}" class="optionImage">
<label id="imageLabel" className= "imageLabel">{{ option.answerText}}</label>
</button>

<button id = "option.id" data-ng-show="option.image == undefined"  className = "imageOptionNoText" ontouchstart="" ng-repeat="option in currentQuestionObject.answerOptions"  ng-click="updateOptionSelect(option.id,option.answerText,option.image,currentQuestionObject.id,$event)"  ng-disabled="buttonDisabled">
<label id="imageLabel" className= "imageLabel">{{ option.answerText }}</label>
</button>

</div>*/}

        </span>

      </div>


    }





    if (this.state.endingPageDisplay) {
      endingPage = <div id="EndingPage_TitleText" className="EndingPage_TitleText">{LABELS.endingPage}</div>
    }


    if (this.state.waitingPageDisplay) {
      waitingPage = <div id="EndingPage_TitleText" className="EndingPage_TitleText">{LABELS.waitingPage}</div>

    }


    if(this.state.disconnectMessageDisplay){
      disconnectMessagePage = <div id="disconnectMessage_Text" className="disconnectMessage_Text">{LABELS.disconnectPage}</div>
    }


    if (this.state.footerBarDisplay) {
      footerBar = <div className="footerbar" >
           <img className = "poweredbyImg" src={poweredby}  width="150" height="50"></img>
      </div>
    }


    return (



      <div className="wrapper">
        {titleBar}

        {questionPage}

        {endingPage}

        {waitingPage}

        {disconnectMessagePage}

        {footerBar}

      </div>


    );
  }


}

export default App;

