const en = {
   titleTextMessage : "Enter four digit Poll Id",
   pollBtnText : "Poll Now",
   logoText: "LivePoll",
   endingPage: "Thank you for taking the poll",
   waitingPage: "Wait for the presenter",
   errorMessage : "Invalid poll id",
   commentBlankMessage: "Please add text before submitting",
   disconnectPage: "Session expired as you were idle. Please refresh the browser and enter the poll id to continue"
  };
  
  export default en;