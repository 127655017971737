import wsSocketService from './webSocketService.js';
import $ from 'jquery';
import ENV from '../env.js';
import Constants from '../constants.js';
const WebSocket = require('isomorphic-ws');


const services = {

  getPollDetails: async function (pollId,connectionId) {

    return new Promise(resolve => {

      var requestParams = {
        "pollId": pollId,
        "connectionId": connectionId
      };

      console.log(requestParams);

      try {
        $.ajax({
          type: 'POST',
          //url: process.env.REACT_APP_GETPOLLDETAILS,
          url: ENV.REACT_APP_GETPOLLDETAILS,
          data: JSON.stringify(requestParams),
          headers: {
            'Content-Type': 'application/json;'
          },
          success: function (data, textStatus, jqXHR) {
            console.log(data);
            resolve(data);
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(errorThrown);
            console.log(jqXHR);
            console.log(textStatus);
            resolve(errorThrown);
          }
        });
      } catch (e) {
        console.log(e);
      }

    });
  },


  sendUserResponse: async function (userResponse) {

    console.log("user responses ======");
    console.log(userResponse);

    return new Promise(resolve => {

      var requestParams = {
        "pollId": Constants.pollId,
        "userId": Constants.webSocketConfig.connectionId,
        "userResponse": JSON.stringify(userResponse)
      };

      console.log(requestParams);

      try {
        $.ajax({
          type: 'POST',
         // url: process.env.REACT_APP_POSTUSERRESPONSE,
          url: ENV.REACT_APP_POSTUSERRESPONSE,
          data: JSON.stringify(requestParams),
          headers: {
            'Content-Type': 'application/json;'
          },
          success: function (data, textStatus, jqXHR) {
            console.log(data);
            resolve(data);
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(errorThrown);
            resolve(errorThrown);
          }
        });
      } catch (e) {
        console.log(e);
      }

    });
  }



}

export default services;
