import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';

// var redisOptions = {
//   host: "storypulse-0001-001.g33caj.0001.use1.cache.amazonaws.com",
//   port: 6379
// }; 
// var redis = require('redis').createClient(redisOptions);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


//serviceWorker.unregister();
