import WebSocket from 'isomorphic-ws';
import Constants from '../constants.js';
import ENV from '../env.js';
import IdleJs from 'idle-js';





//Check whether the user is idle. If so ping to keep the websocket active
var idle = new IdleJs({
    idle: 480000, // idle time in ms, Ping every 8 mins
   // idle: 10000, // idle time in ms, Ping every 8 mins
    events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
    onIdle: function(){
        console.log("-------is idle------");
        wsSocketService.sendMessage({"action": "ping"}); 
        idle.stop()   // stops all tracking
            .reset()  // reset visible and idle state to initial values
            .start();
    }, // callback function to be executed after idle time
    onActive: function(){

    }, // callback function to be executed after back form idleness
    onHide: function(){

    }, // callback function to be executed when window become hidden
    onShow: function(){

    }, // callback function to be executed when window become visible
    keepTracking: true, // set it to false if you want to be notified only on the first idleness change
    startAtIdle: false // set it to true if you want to start in the idle state
  });
  idle.start();







/*========================================================================================================================*/

//AWS API gateway websocket service for real time updates


//var ws = new WebSocket(process.env.REACT_APP_WEBSOCKETURL),
var ws = new WebSocket(ENV.REACT_APP_WEBSOCKETURL),
isOpen = false;


ws.onopen = function open(data) {
    console.log(data);
    console.log('..connected to websocket..');
    wsSocketService.sendMessage({"action": "getconnectionid"});
};



ws.onclose = function close() {
    console.log('disconnected');
    window.setScreen(); // Call the disconnect page function
  };


   
ws.onmessage = function getmessage(message) {

    console.log("======message==========");
    console.log(message);

    var data = JSON.parse(message.data);

    console.log(data);

    if (data.connectionId) {
        Constants.webSocketConfig.connectionId = data.connectionId;
    }
    if (data.currentQuestion) {
        Constants.webSocketConfig.currentQuestion = data.currentQuestion;
        window.setQuestion(); // Call the set question fuction   // Need to try alernative through props

    }
   
    console.log(Constants.webSocketConfig);
   
};



const wsSocketService = {

     sendMessage : function(message){
        ws.send(JSON.stringify(message));    
    }

}

export default wsSocketService;